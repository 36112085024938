<template>
  <div class="aboutBox">
    <pcHeader />
    <div class="bg">
      <img src="../assets/aboutUs.jpg" />
      <div class="banner-title">{{ $t("lang.home.detail.title") }}</div>
    </div>
    <div class="aboutContainer">
      <div class="parp">
        {{ $t("lang.home.detail.subTitle") }}
      </div>
      <div class="titles">{{ $t("lang.aboutUs.titles") }}</div>
      <div class="detail">
        {{ $t("lang.home.detail.container") }}
      </div>
      <div class="titles">{{ $t("lang.aboutUs.title") }}</div>
      <div class="details">
        <div class="part">
          <span
            >• {{ $t("lang.aboutUs.itemOne.title")
            }}{{ $t("lang.aboutUs.symbol") }}</span
          >{{ $t("lang.aboutUs.itemOne.desc") }}
        </div>
        <div class="part">
          <span
            >• {{ $t("lang.aboutUs.itemTwo.title")
            }}{{ $t("lang.aboutUs.symbol") }}</span
          >{{ $t("lang.aboutUs.itemTwo.desc") }}
        </div>
        <div class="part">
          <span
            >• {{ $t("lang.aboutUs.itemThree.title")
            }}{{ $t("lang.aboutUs.symbol") }}</span
          >{{ $t("lang.aboutUs.itemThree.desc") }}
        </div>
        <div class="part">
          <span
            >• {{ $t("lang.aboutUs.itemFour.title")
            }}{{ $t("lang.aboutUs.symbol") }}</span
          >{{ $t("lang.aboutUs.itemFour.desc") }}
        </div>
      </div>
    </div>
    <pcfooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcfooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcfooter,
  },
};
</script>
<style lang="scss">
.aboutBox {
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .aboutContainer {
    width: 1200px;
    margin: 0 auto 140px;
    .parp {
      margin: 120px 0 59px 0;
      font-size: 20px;
      font-weight: bold;
      color: #6d7276;
    }
    .titles {
      margin-bottom: 22px;
      font-size: 26px;
      font-weight: bold;
    }
    .detail {
      font-size: 16px;
      color: #6d7276;
      line-height: 36px;
      margin-bottom: 100px;
    }
    .details {
      margin-top: 20px;
      .part {
        font-size: 16px;
        line-height: 36px;
        color: #6d7276;
        span {
          font-weight: bold;
          color: #6d7276;
        }
      }
    }
  }
}
</style>
